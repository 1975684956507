import React from 'react';
import { useEffect } from 'react';
import { PayPalButtons, usePayPalScriptReducer } from '@paypal/react-paypal-js';
import { callApiWithBodyAndToken } from '../fetch';

// This values are the props in the UI
const currency = 'USD';
const style = {'layout':'vertical'};

// Custom component to wrap the PayPalButtons and handle currency changes
export const PayPalButtonWrapper = ({ token, showSpinner, order, completion }) => {
    //   can be use only inside children of PayPalScriptProviders
    // This is the main reason to wrap the PayPalButtons in a new component
    const [{ options, isPending }, dispatch] = usePayPalScriptReducer();

    useEffect(() => {
        dispatch({
            type: 'resetOptions',
            value: {
                ...options,
                currency: 'USD',
            },
        });
    }, []);

    return (<>
            { (showSpinner && isPending) && <div className='spinner' /> }
            <PayPalButtons
                style={style}
                disabled={order.total == '0.00'}
                forceReRender={[order.total, currency, style]}
                createOrder={(/*data, actions*/) => {
                    // console.log(`createOrder Data: ${JSON.stringify(data)}`);
                    // console.log(`createOrder Actions: ${JSON.stringify(actions)}`);

                    return callApiWithBodyAndToken(token, 'checkout', JSON.stringify(order))
                        .then(function(orderData) {
                            // console.log(`PayPal create response: ${JSON.stringify(orderData)}`)
                            return orderData.data.id;
                        })
                        .catch((error) => {
                            // TODO: Log me.
                            return alert(error);
                        });
                }}
                onApprove={function(data, actions) {
                    // console.log(`onApprove Data: ${JSON.stringify(data)}`);
                    // console.log(`onApprove Order: ${JSON.stringify(pendingOrderId)}`);
                    const orderWrapper = {
                        'paypalOrderId': data.orderID,
                        'order': order,
                        'data': data // TODO
                    }
                    return callApiWithBodyAndToken(token, 'payment', JSON.stringify(orderWrapper))
                    .then(function(orderData) {
                        // console.log(`order post response: ${JSON.stringify(orderData)}`);


                        // Three cases to handle:
                        //   (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
                        //   (2) Other non-recoverable errors -> Show a failure message
                        //   (3) Successful transaction -> Show confirmation or thank you
    
                        // This example reads a v2/checkout/orders capture response, propagated from the server
                        // You could use a different API or structure for your 'orderData'
                        var errorDetail = Array.isArray(orderData.data.details) && orderData.data.details[0];
    
                        if (errorDetail && errorDetail.issue === 'INSTRUMENT_DECLINED') {
                            return actions.restart(); // Recoverable state, per:
                            // https://developer.paypal.com/docs/checkout/integration-features/funding-failure/
                        }
    
                        if (errorDetail) {
                            var msg = 'Sorry, your transaction could not be processed.';
                            if (errorDetail.description) msg += '\n\n' + errorDetail.description;
                            if (orderData.debug_id) msg += ' (' + orderData.debug_id + ')';
                            return alert(msg); // Show a failure message (try to avoid alerts in production environments)
                        } else {
                            completion();
                        }
                    })
                    .catch((error) => {
                        // TODO: Log me.
                        return alert(error);
                    });
                }}
            />
        </>
    );
};

export default PayPalButtonWrapper;