import React, { useEffect } from 'react';
import { useMsal, AuthenticatedTemplate } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';

const ApiAccessWrapper = ({children, accessToken, onSuccess, onError, userAcquired}) => {

    const { instance, inProgress, accounts } = useMsal();

    useEffect(() => {
        // console.log('ApiWrapper useEffect');
        // console.log(`ApiAccessWrapper start ${accessToken}`);
        // console.log(`ApiAccessWrapper start2 ${inProgress}`);
        if (accessToken == null) {
            acquireToken();
        }
    }, [instance, accounts, inProgress])

    const acquireToken = () => {
        if (accessToken) {
            console.log('ApiAccessWrapper success');
            onSuccess(accessToken);
        } else if (!accessToken && inProgress === InteractionStatus.None) {
            const accessTokenRequest = {
                scopes: ['openid', 'offline_access', process.env.REACT_APP_API_PERM],
                account: accounts[0]
            }
            // console.log(`ApiAccessWrapper acquire with: ${JSON.stringify(accessTokenRequest)}`);
            instance.acquireTokenSilent(accessTokenRequest).then((accessTokenResponse) => {
                // console.log(`ApiAccessWrapper acquire success response ${JSON.stringify(accessTokenResponse)}`);
                // console.log(`ApiAccessWrapper acquire success token ${JSON.stringify(accessTokenResponse.accessToken)}`);
                userAcquired(accessTokenResponse.account);
                onSuccess(accessTokenResponse.accessToken);

            }).catch((error) => {
                // console.log(`ApiAccessWrapper error: ${error}`);
                //if (error instanceof InteractionRequiredAuthError) {
                    instance.acquireTokenRedirect(accessTokenRequest).then(function(accessTokenResponse) {
                        userAcquired(accessTokenResponse.account);
                        onSuccess(accessTokenResponse.accessToken);
                    }).catch(function(error) {
                        onError(error);
                    });
                //}
                onError(error);
            })
        }
    };

    return <AuthenticatedTemplate>
        {children}
    </AuthenticatedTemplate>
};

export default ApiAccessWrapper;