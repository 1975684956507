import React from 'react';
import HeaderBarBrand from './HeaderBarBrand';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import { useMsal } from '@azure/msal-react';

const HeaderBar = ({itemCount, total, event, checkOut, startOver, checkIn, state, isAdmin}) => {
  const { instance } = useMsal();

  return (
    <header>
      <nav
        className="navbar has-background-dark is-dark"
        role="navigation"
        aria-label="main navigation"
      >
        <HeaderBarBrand />
        <div>
          {isAdmin && event && (state != 'checkin') &&
            <Button variant="primary" className="mr-2" onClick={checkIn}>
              Check-In
            </Button>
          }
          {itemCount == 0 && (state != 'checkin') &&
            <Button variant="secondary" disabled>
              Checkout
              <Badge className="badge bg-secondary ml-2">{itemCount}</Badge>
            </Button>
          }
          {itemCount > 0 &&
            <Button variant="primary" onClick={checkOut}>
              Checkout ${total}
              <Badge className="badge bg-secondary ml-2">{itemCount}</Badge>
            </Button>
          }
          <Button
            className="ml-2"
            type="button"
            onClick={startOver}
            variant="secondary">
            Start Over
          </Button>
          <Button variant="secondary" className="ml-2" onClick={() => instance.logoutRedirect({ postLogoutRedirectUri: '/' })}>Logout</Button>
        </div>
      </nav>
    </header>
  )
};

export default HeaderBar;
