import React from 'react';
import { useTable, useRowSelect } from 'react-table';

function ButtonTable({ columns, data, rowSelected, size=20 }) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useRowSelect,
    hooks => {
        hooks.visibleColumns.push(columns => columns);
    })

  // Render the UI for your table
  return (
    <>
      <table {...getTableProps()} className='table table-hover'>
        <thead>
          {headerGroups.map((headerGroup, i) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={i}>
              {headerGroup.headers.map((column, j) => (
                <th {...column.getHeaderProps()} key={j}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.slice(0, size).map((row, i) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps({
                onClick: () => {
                  if (rowSelected) {
                    rowSelected(row);
                  }
                }
              })} key={i} >
                {row.cells.map((cell, j) => {
                  return <td className='text-primary fs-2' {...cell.getCellProps()} key={j}>{cell.render('Cell')}</td>
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </>
  )
}

export default ButtonTable;
