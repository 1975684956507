import React from 'react';
// import Button from 'react-bootstrap/Button';

/**
 * Renders snapshot information about the currently selected event
 */
const SelectedEvent = ({event}) => {

    let date = new Date(event.date.value);

    //const openStartList = () => {
    //    window.open(`${process.env.REACT_APP_VELOREG_URL}/field-start-list/${event.id}`);
    //}

    return (
        <div className='align-middle' id="current-event">
            <h4 className='mb-3'>
                <span className='event-name mr-3 align-middle'>{event.label} - {date.toLocaleDateString()}</span>
            </h4>
        </div>
    );
};

export default SelectedEvent;