import React from 'react';
import { useEffect, useState } from 'react';
import { callApiWithToken } from '../fetch';
import { ButtonTable, Styles } from '../components';

export const Events = ({eventSelected, token, startLoading, finishLoading}) => {

    const [events, setEvents] = useState(null);

    const FetchEvents = () => {
        startLoading();
        callApiWithToken(token, 'events').then((response) => {
            // console.log(`Events ${JSON.stringify(response)}`);
            const newEvents = response.data.map(element => {
                let date = new Date(element.date.value)
                return {
                    displayDate: date.toLocaleDateString(),
                    ...element
                }
            });
            setEvents(newEvents);
            finishLoading();
        })
        .catch((error) => {
            console.log(error);
            setEvents([]);
            finishLoading();
        });
    };

    const EventSelected = (event) => {
        eventSelected(event.original);
    }

    const columns = React.useMemo(
        () => [
            {
                Header: 'Select An Event',
                columns: [
                {
                    Header: 'Title',
                    accessor: 'label',
                },
                {
                    Header: 'Date',
                    accessor: 'displayDate',
                },
                ],
            },
        ],
        []
    )

    useEffect(() => {
        // console.log('Events useEffect');
        FetchEvents();
    }, [token]);

    return (
        <Styles>
        {!events &&
            <div>Loading events ...</div>
        }
        {(events && events.length === 0) &&
            <p className='fs-3'>No events currently open for registration. Please 
                check&nbsp;<a href={process.env.REACT_APP_CALENDAR_URL}>the {process.env.REACT_APP_CALENDAR_NAME}</a>&nbsp;for
                information about upcoming events.
            </p>
        }
        {(events && events.length > 0) &&
            <div className='events-table'>
                <ButtonTable columns={columns} data={events} rowSelected={EventSelected} />
            </div>
        }
        </Styles>
    )
}

export default Events;