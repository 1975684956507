import React from 'react';
import { useEffect, useState } from 'react';
import { callApiWithBodyAndToken } from '../fetch';
import { ButtonTable } from './';

const RiderList = (props) => {

    const [displayedFilter, setDisplayedFilter] = useState('');
    const [riders, setRiders] = useState('');
    const [loading, setLoading] = useState(true);

    const LookupRider = () => {
        // console.log(`${displayedFilter} != ${props.riderFilter}`);
        if (displayedFilter != props.riderFilter) {
            props.startLoading();
            // ('callApiWithBodyAndToken');
            callApiWithBodyAndToken(props.token, 'riders', props.riderFilter).then((response) => {
                // console.log(response);
                setRiders(response.data);
                setLoading(false);
                props.finishLoading();
            })
            .catch((error) => {
                // TODO: Log me.
                return alert(error);
            });
            setDisplayedFilter(props.riderFilter)
        }
    };

    const RiderSelected = (rider) => {
        props.riderSelected(rider.original);
    }

    useEffect(() => {
        // console.log('RiderList useEffect');
        LookupRider();
    }, [displayedFilter, props.riderFilter, props.token]);

    const columns = React.useMemo(
        () => [
            {
                Header: 'Select A Rider',
                columns: [
                {
                    Header: '#',
                    accessor: 'default_number.number',
                },
                {
                    Header: 'First Name',
                    accessor: 'first_name',
                },
                {
                    Header: 'Last Name',
                    accessor: 'last_name',
                },
                {
                    Header: 'St',
                    accessor: 'state',
                },
                {
                    Header: 'Team',
                    accessor: 'team',
                },
                {
                    Header: 'Cat',
                    accessor: 'track_cat',
                },
                {
                    Header: 'Age',
                    accessor: 'age',
                },
                ],
            },
        ],
        []
    )

    return (
        <div className='rider-table ml-1 mt-3'>
            {!loading && riders.length === 0 && <div>No results found.</div>}
            {!loading && riders.length > 0 &&
                <ButtonTable columns={columns} data={riders} rowSelected={RiderSelected} size={20} />
            }
        </div>
    )
};

export default RiderList;
