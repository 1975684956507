const currentYear = new Date().getFullYear();
const currentDate = new Date();

export const VerifyLicense = (rider, licenseRequired) => {
    return !licenseRequired || VerifyExpiration(rider);
}

export const VerifyExpiration = (rider) => {
    return rider.license_expiration && Date.parse(rider.license_expiration) > currentDate;
}

export const VerifyWaiver = (rider, event) => {
    return event.license_required == false ||
        (rider !== undefined && rider.waiver_year == currentYear);
}

export const VerifyAtraWaiver = (rider, event) => {
    return !AtraWaiverRequired (event) ||
        rider.waiver_atra == currentYear;
}

export const AtraWaiverRequired = (event) => {
    return process.env.REACT_APP_ATRA_TRACK == 'true'
        && (event.license_required == false
            || process.env.REACT_APP_DUAL_RACE_WAIVER == 'true')
}


export const RiderBirthYear = (rider) => {
    if (rider.age) {
        return currentYear - rider.age;
    } else {
        return currentYear - 1;
    }
}