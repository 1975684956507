import React from 'react';
import { useEffect, useState } from 'react';
import { callApiWithBodyAndToken } from '../fetch';
import { ButtonTable, ManualWaiverForm, Styles, TempNumberForm } from '../components';
import { VerifyWaiver, VerifyAtraWaiver } from '../riderLib';
import { Tab, Tabs } from 'react-bootstrap';


export const CheckIn = ({token, event, startLoading, finishLoading}) => {

    const [entries, setEntries] = useState(null);
    const [tempNumbers, setTempNumbers] = useState([]);
    const [assignedTempNumbers, setAssignedTempNumbers] = useState([]);
    const [seasonNumbers, setSeasonNumbers] = useState([]);
    const [checkInList, setCheckInList] = useState([]);
    const [pendingList, setPendingList] = useState([]);

    const [waiversTitle, setWaiversTitle] = useState('Waivers');
    const [tempNumbersTitle, setTempNumberTitle] = useState('Temp #s');
    const [seasonNumbersTitle, setSeasonNumbersTitle] = useState('Season #s');

    const [waiverRider, setWaiverRider] = useState(null);
    const [tempRider, setTempRider] = useState(null);
    const [tempNumber, setTempNumber] = useState(null);
    const [currentTab, setCurrentTab] = useState('waivers');

    const entriesFilter = {
        event: event.id
    };

    const tempPlaceholder = 999;  // Magic number used to denote unassigned temp number

    const waiverColumns = React.useMemo(
        () => [
            {
                Header: 'Riders required to sign waivers before racing',
                columns: [
                    {
                        Header: 'Number',
                        accessor: 'number',
                    },
                    {
                        Header: 'Rider',
                        accessor: 'rider.label',
                    },
                ],
            },
        ],
        []
    )

    const pendingWaiverColumns = React.useMemo(
        () => [
            {
                Header: 'Riders with waivers marked received',
                columns: [
                    {
                        Header: 'Number',
                        accessor: 'number',
                    },
                    {
                        Header: 'Rider',
                        accessor: 'rider.label',
                    },
                ],
            },
        ],
        []
    )

    const tempColumns = React.useMemo(
        () => [
            {
                Header: 'Riders requiring temp numbers',
                columns: [
                    {
                        Header: 'Rider',
                        accessor: 'rider.label',
                    },
                ],
            },
        ],
        []
    )

    const assignedTempColumns = React.useMemo(
        () => [
            {
                Header: 'Riders currently assigned temp numbers',
                columns: [
                    {
                        Header: 'Rider',
                        accessor: 'rider.label',
                    },
                    {
                        Header: 'Number',
                        accessor: 'number',
                    },
                ],
            },
        ],
        []
    )

    const seasonColumns = React.useMemo(
        () => [
            {
                Header: 'Riders with annual numbers to pick up',
                columns: [
                    {
                        Header: 'Number',
                        accessor: 'number',
                    },
                    {
                        Header: 'Rider',
                        accessor: 'name',
                    },
                ],
            },
        ],
        []
    )

    const FetchEntries = () => {
        startLoading();
        callApiWithBodyAndToken(token, 'entries', JSON.stringify(entriesFilter)).then((response) => {
            console.log(`callApiWithBodyAndToken response ${JSON.stringify(response.data.entries)}`);
            setEntries(response.data.entries);

            var waivers = response.data.entries.map((item) => {
                console.log(`entry ${JSON.stringify(item)}`);
                return {
                    'number': Number(item.number),
                    'rider': item.rider,
                    'id': item.id,
                };
            }).filter((item) => {
                if (item.rider === undefined) {
                    console.log(`Waivers undefined rider`);
                }
                return !VerifyWaiver(item.rider, event) ||
                    !VerifyAtraWaiver(item.rider, event)
            }).filter(function(elem, pos, array) {
                var index = array.findIndex(elem2 => {
                    return elem2.number === elem.number;
                });
                return index === pos;
            }).sort(function(elm1, elm2) {
                if (elm1.number < elm2.number) {
                    return -1;
                } else if (elm1.number > elm2.number) {
                    return 1;
                } else {
                    return 0;
                }
            });

            var pending = waivers.filter(function(item) {
                console.log(`${item.rider.title} waiver_year ${item.rider.waiver_year} pending? ${item.rider.waiver_year == 'Pending'}`);
                return item.rider.waiver_year == 'Pending'; // TODO
            });

            waivers = waivers.filter(function(item) {
                return item.rider.waiver_year != 'Pending'; // TODO
            })

            var temps = response.data.tempNumbers.map((item) => {
                // console.log(`entry ${JSON.stringify(item)}`);
                return {
                    'number': Number(item.number),
                    'rider': item.rider,
                    'id': item.id
                };
            }).filter(function(elem, pos, array) {
                var index = array.findIndex(elem2 => {
                    return elem2.number === elem.number;
                });
                return index === pos && elem.number == tempPlaceholder;
            }).sort(function(elm1, elm2) {
                if (elm1.number < elm2.number) {
                    return -1;
                } else if (elm1.number > elm2.number) {
                    return 1;
                } else {
                    return 0;
                }
            });

            var assignedTemps = response.data.tempNumbers.map((item) => {
                // console.log(`entry ${JSON.stringify(item)}`);
                return {
                    'number': Number(item.number),
                    'rider': item.rider,
                    'id': item.id
                };
            }).filter(function(elem, pos, array) {
                var index = array.findIndex(elem2 => {
                    return elem2.number === elem.number;
                });
                return index === pos && elem.number != tempPlaceholder;
            }).sort(function(elm1, elm2) {
                if (elm1.number < elm2.number) {
                    return -1;
                } else if (elm1.number > elm2.number) {
                    return 1;
                } else {
                    return 0;
                }
            });


            var annuals = response.data.annualNumbers.map((item) => {
                return {
                    'number': Number(item.number.number),
                    'name': item.name,
                    'id': item.id
                };
            }).filter(function(elem, pos, array) {
                var index = array.findIndex(elem2 => {
                    return elem2.number === elem.number;
                });
                return index === pos;
            }).sort(function(elm1, elm2) {
                if (elm1.number < elm2.number) {
                    return -1;
                } else if (elm1.number > elm2.number) {
                    return 1;
                } else {
                    return 0;
                }
            });

            console.log(`waivers ${JSON.stringify(waivers)}`);
            setCheckInList(waivers);
            setPendingList(pending);
            setWaiversTitle(`Waivers (${waivers.length})`);
            setTempNumbers(temps);
            setTempNumberTitle(`Temp Numbers (${temps.length})`);
            setAssignedTempNumbers(assignedTemps);
            setSeasonNumbers(annuals);
            setSeasonNumbersTitle(`Annual Numbers (${annuals.length})`);

            finishLoading();
        })
        .catch((error) => {
            finishLoading();
            // TODO: Log me.
            console.log(error);
            return alert(error);
        });
    };

    const WaiverSelected = (row) => {
        console.log(`WaiverSelected:: ${JSON.stringify(row.original)}`);
        setWaiverRider(row.original.rider);
    }

    const PendingWaiverSelected = (row) => {
        console.log(`PendingWaiverSelected:: ${JSON.stringify(row.original)}`);
    }

    const TempSelected = (row) => {
        console.log(`TempSelected:: ${JSON.stringify(row.original)}`);
        setTempRider(row.original);
    }

    const SeasonSelected = (row) => {
        console.log(`SeasonSelected:: ${JSON.stringify(row.original)}`);
    }

    const TempFormComplete = () => {
        return tempNumber != '';
    }

    const AssignTempNumber = () => {
        console.log(`Assign ${tempNumber} to entry ${tempRider.id}`);
        entries.filter(function(item) {
            return item.rider.id == tempRider.rider.id;
        }).forEach(function(item) {
            console.log(`Assigning ${tempNumber} to entry ${item.id}`);
            PostTempNumberAssignment(item.id, tempNumber);
        });
    }

    const PostTempNumberAssignment = (entryId, number) => {

        const tempNumberBody = {
            entryId: entryId,
            number: number
        };
        startLoading();
        callApiWithBodyAndToken(token, 'assign-temp', JSON.stringify(tempNumberBody)).then((response) => {
            console.log(`AssignTempResult ${JSON.stringify(response)}`);
            setTempNumber(null);
            setTempRider(null);
            finishLoading();
            FetchEntries('temp');
        })
        .catch((error) => {
            finishLoading();
            alert(error);
        });
    }

    const SetWaiverRecieved = () => {
        console.log(`Receive USAC wavier for ${waiverRider.title}`);
        PostWaiver('usac');
    }

    const SetATRAWaiverRecieved = () => {
        console.log(`Receive USAC wavier for ${waiverRider.title}`);
        PostWaiver('atra');
    }

    const PostWaiver = (type) => {
        const waiverBody = {
            riderId: waiverRider.id,
            email: waiverRider.email,
            type: type
        }
        startLoading();
        callApiWithBodyAndToken(token, 'accept-waiver', JSON.stringify(waiverBody)).then(() => {
            setWaiverRider(null);
            finishLoading();
            FetchEntries('season');
            FetchEntries();
        })
        .catch((error) => {
            finishLoading();
            alert(error);
        });
    }

    const Cancel = () => {
        setTempRider(null);
        setWaiverRider(null);
    }

    useEffect(() => {
        console.log('Events useEffect');
        FetchEntries();
    }, [token, event]);

    return (
        <Styles>
        {tempRider &&
            <TempNumberForm
                entry={tempRider}
                SetTempNumber={setTempNumber}
                TempFormComplete={TempFormComplete}
                AssignTempNumber={AssignTempNumber}
                Cancel={Cancel} />
        }
        {waiverRider &&
            <ManualWaiverForm
                event={event}
                rider={waiverRider}
                SetWaiverRecieved={SetWaiverRecieved}
                SetATRAWaiverRecieved={SetATRAWaiverRecieved}
                Cancel={Cancel} />
        }
        {(!waiverRider && !tempRider && !entries && !tempNumbers && !seasonNumbers) &&
            <div>Loading entries ...</div>
        }
        {(!waiverRider && !tempRider && (entries || tempNumbers || seasonNumbers)) &&
            <Tabs
                id="checkin-table"
                activeKey={currentTab}
                onSelect={(k) => setCurrentTab(k)}
                className="mb-3"
            >
                <Tab eventKey="waivers" title={waiversTitle}>
                    <div className='entries-table'>
                        <ButtonTable
                            columns={waiverColumns}
                            data={checkInList}
                            rowSelected={WaiverSelected}
                            size={checkInList.length} />
                    </div>
                    <div className='entries-table'>
                        <ButtonTable
                            columns={pendingWaiverColumns}
                            data={pendingList}
                            rowSelected={PendingWaiverSelected}
                            size={pendingList.length} />
                    </div>
                </Tab>
                <Tab eventKey="temp" title={tempNumbersTitle}>
                    <div className='temp-table'>
                        <ButtonTable
                            columns={tempColumns}
                            data={tempNumbers}
                            rowSelected={TempSelected}
                            size={tempNumbers.length} />
                    </div>
                    <div className='temp-table'>
                        <ButtonTable
                            columns={assignedTempColumns}
                            data={assignedTempNumbers}
                            rowSelected={TempSelected}
                            size={assignedTempNumbers.length} />
                    </div>
                </Tab>
                <Tab eventKey="season" title={seasonNumbersTitle}>
                    <div className='season-table'>
                        <ButtonTable
                            columns={seasonColumns}
                            data={seasonNumbers}
                            rowSelected={SeasonSelected}
                            size={seasonNumbers.length} />
                    </div>
                </Tab>
            </Tabs>
        }
        </Styles>
    )
}

export default CheckIn;