import React from 'react';
import { NavLink } from 'react-router-dom';

const HeaderBarBrand = () => (
  <div className="navbar-brand">
    <NavLink to="/" className="navbar-item nav-home">
      <span className="brand-first">{process.env.REACT_APP_SITE_HEADER_ONE}</span>
      <span className="brand-second">{process.env.REACT_APP_SITE_HEADER_TWO}</span>
      <span className="brand-third">{process.env.REACT_APP_SITE_HEADER_THREE}</span>
      <span className="brand-forth">{process.env.REACT_APP_SITE_HEADER_FOUR}</span>
    </NavLink>
  </div>
);

export default HeaderBarBrand;
