import React from 'react';
import { useEffect, useState } from 'react';
import { callApiWithToken } from '../fetch';
import { ButtonTable, Styles } from '../components';
import { CloseButton, OverlayTrigger, Tooltip} from 'react-bootstrap';

export const AvailableNumbers = ({numberSelected, closeNumberSelection, rider, token, startLoading, finishLoading}) => {

    const [numbers, setNumbers] = useState([]);
    const numbersPath = 'available-numbers?filter%5Bavailable%5D%5Bvalue%5D=1&range=500&sort=number';
    const reservedPath = `reserved-numbers?rider_id=${rider.id}`;

    const FetchNumbers = () => {
        startLoading();

        // TODO: Need to proxy through functions...
        const availablePromise = async () => {
            return callApiWithToken(token, numbersPath);
        }

        const reservedPromise = async () => {
            return callApiWithToken(token, reservedPath);
        }

        Promise.all([reservedPromise(), availablePromise()])
        .then((values) => {
            const numbers = [...values[0].data, ...values[1].data];
            setNumbers(numbers);
            finishLoading();
        })
        .catch((error) => {
            // TODO: Log me.
            setNumbers([]);
            finishLoading();
            return alert(error);
        });
    };

    const NumberSelected = (number) => {
        numberSelected(number.original, rider);
    }

    const columns = React.useMemo(
        () => [
            {
                Header: 'Select A Number',
                columns: [
                {
                    Header: 'Numbers',
                    accessor: 'number',
                }
                ],
            },
        ],
        []
    )

    useEffect(() => {
        // console.log('Numbers useEffect');
        let didCancel = false;
        
        if (!didCancel) {
            FetchNumbers();
        }

        return () => {
            didCancel = true;
        }
    }, [token]);

    return (
        <Styles>
            <div className='numbers-table'>
                <OverlayTrigger
                    key={rider.id}
                    overlay={
                        <Tooltip>
                            Return to Order.
                        </Tooltip>
                    }
                >
                    <CloseButton onClick={() => closeNumberSelection()} />
                </OverlayTrigger>
                {numbers &&
                    <ButtonTable columns={columns} data={numbers} rowSelected={NumberSelected} size='300' />
                }
                {!numbers &&
                    <div>No numbers found, please try again later.</div>
                }
                <div className='footnote'>
                    Availability Notes:
                    <ul>
                        <li>Numbers 1, 2 and 3 are reserved for the top 3 of the previous years ROTY for the P/1/2 field.</li>
                        <li>Numbers used in the previous year are reserved and only available to that rider, unless they choose to use a different number this year.</li>
                    </ul>
                </div>
            </div>
        </Styles>
    )
}

export default AvailableNumbers;