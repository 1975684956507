import React from 'react';
import { CloseButton } from 'react-bootstrap';
import { ChargeSummary } from '../components'

export const Checkout = ({token, order, close, completion}) => {

    return (
        <div>
            <CloseButton onClick={() => close()} />
            <div className='checkout-wrapper'>
                <>
                    <ChargeSummary
                        token={token}
                        order={order}
                        entries={order.entryFees}
                        numbers={order.numberFees}
                        discounts={order.discountAmount}
                        discountNote={order.discountNote}
                        credits={order.creditAmount}
                        fees={order.fees}
                        balance={order.balance}
                        completion={completion} />
                </>
            </div>
        </div>
    );
}

export default Checkout;