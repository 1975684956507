import React from 'react';
import { useState } from 'react';
import { CloseButton, Button, Form } from 'react-bootstrap';
import { RiderList } from '../components';
import { VerifyLicense } from '../riderLib';

export const Riders = ({token, /*idpUser,*/ licenseRequired, riderSelected, close, startLoading, finishLoading}) => {

    const [bibNumber, setBibNumber] = useState('');
    const [licNumber, setLicNumber] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [age, setAge] = useState('');
    const [address, setAddress] = useState('');
    const [address2, setAddress2] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zip, setZip] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [manualEntry, setMnualEntry] = useState(false);

    const currentYear = new Date().getFullYear();

    const SubmitRiderFilter = () => {
        setSubmitted(true);
    }

    const CloseResults = () => {
        setSubmitted(false);
    }

    const ResetRiderFilter = () => {
        setBibNumber('');
        setLicNumber('');
        setFirstName('');
        setLastName('');
        setSubmitted(false);
    }

    const FilterSet = () => {
        return bibNumber.length > 0 || licNumber.length > 3 || firstName.length > 1 || lastName.length > 1;
    }

    const FilterClear = () => {
        return bibNumber.length == 0 && licNumber.length == 0 && firstName.length == 0 && lastName.length == 0;
    }

    const ShowManualEntry = () => {
        setMnualEntry(true);
    }

    const ShowSearch = () => {
        setMnualEntry(false);
    }

    const RiderFilter = () => {
        return JSON.stringify({
            bibNumber: bibNumber,
            licNumber: licNumber,
            firstName: firstName,
            lastName: lastName
        });
    }

    const RiderSelected = (rider) => {
        if (!VerifyLicense(rider, licenseRequired)) {
            if (rider.license_expiration) {
                alert(`The USAC license for ${rider.first_name} ${rider.last_name} expired on ${rider.license_expiration}. Please ensure that the rider's license has been renewed for ${currentYear}.\n\nIf you recently renewed, please allow up to 24 hours for the rider DB to update.`);
            } else {
                alert(`A valid USAC license for ${rider.first_name} ${rider.last_name} could not be found. Please ensure that the rider's license has been purchased for ${currentYear}.\n\nIf you recently purchased a license, please allow up to 24 hours for the rider DB to update.`);
            }
        } else {
            const keyedRider = { ...rider, key: rider.id };
            riderSelected(keyedRider);
        }
    }

    const RefineSearch = () => {
        setSubmitted(false);
    }

    const FormComplete = () => {
        return firstName && lastName && address && city && state && zip;
    }

    const SubmitRiderEntry = () => {
        const rider = {
            key: firstName + lastName + zip,
            title: 'manual ' + firstName + ' ' + lastName + ' ' + zip,
            first_name: firstName,
            last_name: lastName,
            age: age,
            address: address,
            address2: address2,
            city: city,
            state: state,
            zip: zip,
//            idp_users: [idpUser],
            track_cat: '5',
            gender: 'N/A'

        }
        riderSelected(rider)
    }

    const NumbersOnly = (e) => {
        if (!/[0-9]/.test(e.key) && event.key != 'Enter') {
            e.preventDefault();
        }
    }

    const RiderEntryForm = () => {
        return <>
            <Button className="mb-3" variant="primary" onClick={ShowSearch}>Search For Existing Rider</Button>
            <Form>
                <CloseButton onClick={() => close()} />
                <h3>Enter New Person</h3>
                <Form.Group className="mb-3" controlId="formFirstName">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control type="text" onChange={(e) => setFirstName(e.target.value)} />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formLastName">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control type="text" onChange={(e) => setLastName(e.target.value)} />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formAge">
                    <Form.Label>Age</Form.Label>
                    <Form.Control type="text" maxLength="3" onKeyPress={NumbersOnly} onChange={(e) => setAge(e.target.value)} />
                </Form.Group>

                <Form.Group className="mb-3" controlId="address">
                    <Form.Label>Address</Form.Label>
                    <Form.Control type="text" onChange={(e) => setAddress(e.target.value)} />
                </Form.Group>

                <Form.Group className="mb-3" controlId="address2">
                    <Form.Control type="text" onChange={(e) => setAddress2(e.target.value)} />
                </Form.Group>

                <Form.Group className="mb-3" controlId="city">
                    <Form.Label>City</Form.Label>
                    <Form.Control type="text" onChange={(e) => setCity(e.target.value)} />
                </Form.Group>

                <Form.Group className="mb-3" controlId="state">
                    <Form.Label>State</Form.Label>
                    <Form.Control type="text" maxLength="2" onChange={(e) => setState(e.target.value)} />
                </Form.Group>

                <Form.Group className="mb-3" controlId="zip">
                    <Form.Label>Zip</Form.Label>
                    <Form.Control type="text" maxLength="5" onKeyPress={NumbersOnly} onChange={(e) => setZip(e.target.value)} />
                </Form.Group>

                {!FormComplete() &&
                    <Button variant="primary" disabled onClick={SubmitRiderEntry}>Save Person</Button>
                }
                {FormComplete() &&
                    <Button variant="primary" type="submit" onClick={SubmitRiderEntry}>Save Person</Button>
                }
            </Form>
        </>
    }

    const RiderSearchForm = () => {
        return <>
            {licenseRequired == false &&
                <Button variant="primary" className="mb-3" onClick={ShowManualEntry}>
                    Add Unlicensed Person
                </Button>
            }
            <Form>
                <CloseButton onClick={() => close()} />
                <h3>Rider Lookup</h3>
                <Form.Group className="mb-3" controlId="formSeasonNumber">
                    <Form.Label>Season Number</Form.Label>
                    <Form.Control type="text" value={bibNumber} onKeyPress={NumbersOnly} onChange={(e) => setBibNumber(e.target.value)} />
                    <Form.Text className="text-muted">
                    <p>Leave this blank if the rider has not yet paid for a  {currentYear} season number.</p>
                    <p>Typically the easiest option if the rider already has a {currentYear} season number.</p>
                    </Form.Text>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formLicenseNumber">
                    <Form.Label>USAC License Number</Form.Label>
                    <Form.Control type="text" value={licNumber} onKeyPress={NumbersOnly} onChange={(e) => setLicNumber(e.target.value)} />
                    <Form.Text className="text-muted">
                    Typically the best option if the rider doesn&apos;t yet have a {currentYear} number and doesn&apos;t have a very unique name.
                    </Form.Text>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formFirstName">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formLastName">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                </Form.Group>

                {!FilterSet() &&
                    <Button variant="primary" disabled>Search</Button>
                }
                {FilterSet() &&
                    <Button variant="primary" type="submit" onClick={SubmitRiderFilter}>Search</Button>
                }
                {FilterClear() &&
                    <Button variant="primary" disabled className='ml-2'>Reset</Button>
                }
                {!FilterClear() &&
                    <Button variant="primary" className='ml-2' onClick={ResetRiderFilter}>Reset</Button>
                }
            </Form>
        </>
    }

    return <div>
        {submitted === false &&
            <div>
                {(licenseRequired == false && manualEntry == true) &&
                    RiderEntryForm()
                }
                {(licenseRequired == true || manualEntry == false) &&
                    RiderSearchForm()
                }
            </div>
        }
        {submitted === true &&
            <div>
                <Button
                    type="button"
                    onClick={() => RefineSearch()}
                    variant="primary"
                    size="lg">
                    Refine Search
                </Button>
                <CloseButton onClick={CloseResults} />
                <RiderList
                    riderFilter={RiderFilter()}
                    token={token}
                    riderSelected={RiderSelected}
                    startLoading={startLoading}
                    finishLoading={finishLoading}
                />

            </div>
        }
        </div>
}

export default Riders;