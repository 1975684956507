import styled from 'styled-components';

const Styles = styled.div`
  padding: 1rem;

  table {
    border-spacing: 0;
    border: 0px solid black;

    th,
    td {
      margin: 0;
      padding: 0.5rem;
    }
  }
`

Styles.displayName = 'style';

export default Styles;