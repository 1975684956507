import React from 'react';
import { CloseButton, Button, Form } from 'react-bootstrap';


const TempNumberForm = ({entry, SetTempNumber, TempFormComplete, AssignTempNumber, Cancel}) => {

    return (
        <Form>
            <CloseButton onClick={() => Cancel()} />
            <h4>Assign Temp Number for {entry.rider.title}</h4>
            <Form.Group className="mb-3" controlId="formFirstName">
                <Form.Label>Temp Number</Form.Label>
                <Form.Control type="number" onChange={(e) => SetTempNumber(e.target.value)} />
            </Form.Group>
            {!TempFormComplete() &&
                <Button variant="primary" disabled onClick={AssignTempNumber}>Assign Temp Number</Button>
            }
            {TempFormComplete() &&
                <Button variant="primary" onClick={AssignTempNumber}>Assign Temp Number</Button>
            }
        </Form>
    );
}

export default TempNumberForm;