import React from 'react';
import { CloseButton, Button, Form } from 'react-bootstrap';

const ManualWaiverForm = ({event, rider, SetWaiverRecieved, SetATRAWaiverRecieved, Cancel}) => {

    return (
        <Form>
            <CloseButton onClick={() => Cancel()} />
            <h4>Receive waiver for {rider.title}</h4>
            {event.license_required &&
                <Button variant="primary" onClick={SetWaiverRecieved}>Receive USAC Waiver</Button>
            }
            {!event.license_required &&
                <Button variant="primary" onClick={SetATRAWaiverRecieved}>Receive ATRA Waiver</Button>
            }
        </Form>
    );
}

export default ManualWaiverForm;