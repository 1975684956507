import React, { useEffect } from 'react';
import { useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import { Button } from 'react-bootstrap';
import LoadingOverlay from 'react-loading-overlay';

import './styles.scss';

import { MsalProvider } from '@azure/msal-react';
import { callApiWithToken, callApiWithBodyAndToken } from './fetch';
import { AvailableRiderFields } from './fieldLib';
import { VerifyWaiver, VerifyAtraWaiver } from './riderLib';

import { ApiAccessWrapper, HeaderBar, SelectedEvent } from './components';

import AvailableNumbers from './pages/AvailableNumbers';
import Cart from './pages/Cart';
import Checkout from './pages/Checkout';
import Confirmation from './pages/Confirmation';
import Events from './pages/Events';
import Riders from './pages/Riders';
import CheckIn from './pages/CheckIn';
import { Waiver } from './pages/waivers/Waiver';


const App = ({ instance }) => {

  const [accessToken, setAccessToken] = useState(null);
  const [idpUser, setIdpUser] = useState(null);
  const [charges, setCharges] = useState({'total': 0, 'numberFees': 0, 'entryFees': 0, 'creditAmount': 0, 'discountAmount': 0});
  const [event, setEvent] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [order, setOrder] = useState({});
  const [riders, setRiders] = useState([]);
  const [selectedRider, setSelectedRider] = useState(null);
  const [tokenError, setTokenError] = useState(null);
  const [step, setStep] = useState('events');
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    // console.log(`App useEffect accessToken ${accessToken}`);
    // console.log(`App useEffect instance ${instance}`);
    // console.log(`App useEffect riders ${riders}`);
    // console.log(`App useEffect event ${event}`);
    UpdateCartCosts();
  }, [accessToken, riders, event]);

  useEffect(() => {
    if (event && event.race_event && riders && riders.length > 0) {
      riders.forEach(function(rider) {
        if (!rider.default_number && !rider.assignedNumber && !rider.tempNumber) {
          AutoSelectNumber(rider);
        }
      })
    }
  }, [riders]);

  const TokenAcquired = (token) => {
    // console.log(`TokenAcquired ${token}`);
    setAccessToken(token);

    callApiWithToken(token, 'user-profile')
    .then((profileResponse) => {
      // console.log(`Profile: ${JSON.stringify(profileResponse.data)}`);
      if (profileResponse.data) {
        setIsAdmin(profileResponse.data.is_admin);
      }
    });
  }

  const TokenError = (error) => {
    setTokenError(error);
  }

  const SetCheckIn = () => {
    setStep('checkin');
  }

  const StartOver = () => {
    window.location.reload();
  }

  const StartLoading = () => {
    setIsLoading(true);
  };

  const FinishLoading = () => {
    setIsLoading(false);
  };

  const AvailableFields = (rider) => {
    return AvailableRiderFields(event, rider);
  }

  const EventSelected = (selectedEvent) => {
    setEvent(selectedEvent);
    setStep('riders');
  }

  const deleteProperty = ({[key]: _, ...newObj}, key) => newObj;

  const AutoSelectNumber = (rider) => {
    const reservedPath = `reserved-numbers?rider_id=${rider.id}`;

    callApiWithToken(accessToken, reservedPath)
    .then((reservedResponse) => {
      if (reservedResponse.data && reservedResponse.data[0]) {
        SetRiderNumber(reservedResponse.data[0], rider);
      }
    })
  }

  const SetRiderNumber = (selectedNumber, rider) => {
    setRiders(riders.map((item) => {
      if (item.key == rider.key) {
        const withoutNumbers = deleteProperty(item, 'tempNumber');
        return {
          ...withoutNumbers,
          assignedNumber: selectedNumber
        }
      } else {
        return item;
      }
    }));
  }

  const NumberSelected = (selectedNumber, rider) => {
    SetRiderNumber(selectedNumber, rider);
    setStep('cart');
  }

  const AssignTempNumber = (riderKey) => {
    setRiders(riders.map((item) => {
      if (item.key == riderKey) {
        const withoutNumbers = deleteProperty(item, 'assignedNumber');
        return {
          ...withoutNumbers,
          tempNumber: 999
        }
      } else {
        return item;
      }
    }));
  }

  const SelectRiderSeasonNumber = (rider) => {
    setSelectedRider(rider);
    setStep('numbers');
  }

  const CloseNumberSelection = () => {
    setSelectedRider(null);
    ShowCart();
  }

  const OpenAddRider = () => {
    setStep('riders');
  }

  const IdpUserAcquired = (user) => {
    // console.log(`IdpUserAcquired ${JSON.stringify(user)}`);
    setIdpUser(user);
  }

  const GetIdpUser = () => {
    // console.log(`GetIdpUser ${idpUser.username} ${idpUser.email}`);
    return {
      username: idpUser.username,
      idp: idpUser.idTokenClaims.idp ? idpUser.idTokenClaims.idp : '',
      sub: idpUser.idTokenClaims.sub ? idpUser.idTokenClaims.sub : '',
      email: (idpUser.idTokenClaims.emails && idpUser.idTokenClaims.emails[0]) ? idpUser.idTokenClaims.emails[0] : '',
      first_name: idpUser.idTokenClaims.first_name ? idpUser.idTokenClaims.first_name : '',
      last_name: idpUser.idTokenClaims.last_name ? idpUser.idTokenClaims.last_name : ''
    }
  }

  const RiderAlreadySelected = (selectedRider) => {
    const reSelectedRiders = riders.filter((rider) => {
      return rider.id === selectedRider.id;
    })
    return reSelectedRiders.length > 0;
  }

  const AddRider = (rider) => {
    setSelectedRider(rider);
    CheckWaivers(rider);
  }

  const CheckWaivers = (rider) => {
    if (!VerifyWaiver(rider, event)) {
      setStep('usacWaiver');
    } else if (!VerifyAtraWaiver(rider, event)) {
      setStep('atraWaiver');
    } else {
      LoadRiderFields(rider);
    }
  }

  const SkipWaiver = () => {
    LoadRiderFields(selectedRider);
  }

  const WaiverComplete = (patchedRider) => {
    setSelectedRider(patchedRider);
    CheckWaivers(patchedRider);
  }

  const LoadRiderFields = (selectedRider) => {
    StartLoading()
    if (selectedRider.id && RiderAlreadySelected(selectedRider)) {
      FinishLoading();
      ShowCart();
    } else if (selectedRider.id) {
      callApiWithBodyAndToken(accessToken, 'fields', `{"riderId":${selectedRider.id}, "eventId":${event.id}}`)
        .then((response) => {
          const currentRiderRegistrations = response.data.map((item) => {
            return item.field.id;
          });
          AddRiderWithFields(selectedRider, currentRiderRegistrations);
          FinishLoading();
        })
        .catch((error) => {
          FinishLoading();
          alert(error);
      });
    } else {
      AddRiderWithFields(selectedRider, []);
      FinishLoading();
    }
  }

  const AddRiderWithFields = (selectedRider, registeredFields) => {
    const availableFileds = AvailableFields(selectedRider);

    // TODO: Add more intelligent auto selection.
    var autoSelectedFields = [];
    if (availableFileds && availableFileds.length > 0 && registeredFields.length == 0) {
      autoSelectedFields = [availableFileds[0].id];
    }
    const selectedRiderWithFields = {
      ...selectedRider,
      registeredFields: registeredFields,
      availableFields: availableFileds,
      selectedFields: autoSelectedFields
    }
    setRiders(riders => {
      return [
        ...riders.filter((rider) => {
          return rider.id != selectedRiderWithFields.id;
        }),
        selectedRiderWithFields
      ]
    });
    ShowCart();
  }

  const GetOrder = (event = 0, riders = []) => {
    const cleanEvent = deleteProperty(event, 'displayDate');
    return new Promise((resolve) => {
      const body = {
        event: cleanEvent,
        riders: riders,
        user: JSON.stringify(idpUser)
      }
      callApiWithBodyAndToken(accessToken, 'orders', JSON.stringify(body))
        .then((response) => {
          // console.log(`GetOrder ${JSON.stringify(response)}`);
          if (response && response.data) {
            setOrder(response.data);
            resolve(response.data);
          } else {
            resolve({
              event: 0,
              lineItems: [],
              total: 0,
              numberFees: 0,
              entryFees: 0,
              discountAmount: 0,
              creditAmount: 0
            })
          }
        })
        .catch((error) => {
          // TODO: Log me.
          alert(error);
        });
    });
  }

  const RemoveRider = (riderKey) => {
    setRiders(riders.filter((item) => item.key !== riderKey));
  }

  const AddSelectedField = (riderKey, fieldId) => {
    // console.log(`${riderKey} ${fieldId}`);
    const newRiders = riders.map(item => {
      if (item.key == riderKey) {
        const newRiderFields = [...item.selectedFields, fieldId];
        item.selectedFields = newRiderFields;
        return item;
      }
      return item;
    });
    setRiders(newRiders);
  }

  const RemoveSelectedField = (riderKey, riderFieldId) => {
    // console.log(`RemoveSelectedField ${riderKey} ${riderFieldId}`);
    const newRiders = riders.map(item => {
      // console.log(`RemoveSelectedField raw item ${JSON.stringify(item)}`);
      if (item.key == riderKey) {
        const newRiderFields = item.selectedFields.filter((fieldId) => fieldId !== riderFieldId);
        item.selectedFields = newRiderFields;
        // console.log(`RemoveSelectedField adjusted item ${item}`);
        return item;
      }
      return item;
    });
    setRiders(newRiders);
  }

  const UpdateCartCosts = () => {
    if (accessToken && event && riders) {
      // console.log(`JRTEST UpdateCartCosts ${JSON.stringify(order)}`);
      GetOrder(event, riders).then((response) => {
        setCharges({
          'total': response.total,
          'numberFees': response.numberFees,
          'entryFees': response.entryFees,
          'creditAmount': response.creditAmount,
          'discountAmount': response.discountAmount
        });
      });
    }
  }

  const ShowCart = () => {
    setStep('cart');
  }

  const ShowCheckout = () => {
    // console.log(`order ${order.entryFees} or ${order.lineItems.length} ${JSON.stringify(order)}`)
    if (event.race_event && HasNumberlessRiders()) {
        alert('Numbers Required\n\nAll riders must have a number to race.');
    } else if (Number(order.entryFees) > 0 || order.lineItems.length > 0 || confirm('Not Racing?\n\nAre you sure you want to checkout without registering to race?')) {
      setStep('checkout');
    }
  }

  const HasNumberlessRiders = () => {
    const numberlessRiders = riders.filter((rider) => {
      return rider.default_number == null && rider.tempNumber == null &&
        rider.assignedNumber == null && rider.replacementNumber == null;
    });
    return numberlessRiders.length > 0;
  }

  const CheckoutComplete = () => {
    ClearOrder();
    setStep('confirmation');
  }

  const ClearOrder = () => {
    setCharges({'total': 0, 'numberFees': 0, 'entryFees': 0, 'creditAmount': 0, 'discountAmount': 0});
    setOrder({});
    setRiders([]);
    setSelectedRider(null);
  }

  const CurrentStep = () => {
    switch (step) {
      case 'events':
        return (
          <Events
            token={accessToken}
            eventSelected={EventSelected}
            startLoading={StartLoading}
            finishLoading={FinishLoading} />
        );
      case 'riders':
        return (
          <Riders
            token={accessToken}
            idpUser={GetIdpUser}
            licenseRequired={event.license_required}
            riderSelected={AddRider}
            close={ShowCart}
            startLoading={StartLoading}
            finishLoading={FinishLoading} />
        );
      case 'usacWaiver':
        return (
          <Waiver
            token={accessToken}
            rider={selectedRider}
            idpUser={idpUser}
            waiverType='usac'
            skipWaiver={SkipWaiver}
            acceptWaiver={WaiverComplete}
          />
        );
      case 'atraWaiver':
        return (
          <Waiver
            token={accessToken}
            rider={selectedRider}
            idpUser={idpUser}
            waiverType='atra'
            skipWaiver={SkipWaiver}
            acceptWaiver={WaiverComplete}
          />
        );
      case 'cart':
        return (
          <div className='my-3'>
            <Button
              type="button"
              onClick={() => OpenAddRider()}
              variant="primary"
              size="lg">
              Add Rider
            </Button>
            <Cart 
              token={accessToken}
              event={event}
              riders={riders}
              itemCount={(order.lineItems ? order.lineItems.length : 0)}
              total={charges.total}
              checkout={ShowCheckout}
              removeRider={RemoveRider}
              addRiderField={AddSelectedField}
              removeRiderField={RemoveSelectedField}
              assignSeasonNumber={SelectRiderSeasonNumber}
              assignTempNumber={AssignTempNumber} />
          </div>
        );
      case 'checkout':
        return (
          <>
            <Checkout
              token={accessToken}
              order={order}
              close={ShowCart}
              completion={CheckoutComplete} />
          </>
        );
      case 'confirmation':
        return (
          <Confirmation
            event={event}
            restart={StartOver} />
        );
      case 'numbers':
        return (
          <AvailableNumbers
            token={accessToken}
            rider={selectedRider}
            startLoading={StartLoading}
            finishLoading={FinishLoading}
            numberSelected={NumberSelected}
            closeNumberSelection={CloseNumberSelection} />
        );
      case 'checkin':
        return (
          <CheckIn
            token={accessToken}
            event={event}
            startLoading={StartLoading}
            finishLoading={FinishLoading} />
        );
      default:
        return (
          <div>State Error</div>
        );
    }
  }

  return (
      <MsalProvider instance={instance}>
        <ApiAccessWrapper
          onSuccess={TokenAcquired}
          onError={TokenError}
          userAcquired={IdpUserAcquired} >
          <LoadingOverlay
            active={isLoading || !accessToken}
            spinner
            text='Loading...'
            >
            <HeaderBar
              itemCount={(order.lineItems ? order.lineItems.length : 0)}
              total={charges.total}
              event={event}
              checkOut={ShowCheckout}
              startOver={StartOver}
              checkIn={SetCheckIn}
              state={step}
              isAdmin={isAdmin} />
            <div className="container section">
              <main className='mb-4'>
                {tokenError &&
                  <Alert variant="danger" onClose={() => setTokenError(null)} dismissible>
                    <Alert.Heading>Error</Alert.Heading>
                    <p>{tokenError}</p>
                  </Alert>
                }
                {event &&
                  <SelectedEvent event={event} />
                }
                {accessToken && CurrentStep()}
              </main>
            </div>
          </LoadingOverlay>
        </ApiAccessWrapper>
      </MsalProvider>
  );
}

export default App;
