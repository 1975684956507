import React from 'react';
import Button from 'react-bootstrap/Button';
import Styles from './Styles';
import { callApiWithBodyAndToken } from '../fetch';
import { PayPalButtonWrapper } from '../components'
import { PayPalScriptProvider } from '@paypal/react-paypal-js';

const SubmitZeroBalanceOrder = (token, order, completion) => {
    const orderWrapper = {
        'paypalOrderId': -1,
        'order': order,
        'data': {}
    }

    callApiWithBodyAndToken(token, 'payment', JSON.stringify(orderWrapper))
    .then(() => {
        // console.log(JSON.stringify(response));
        completion();
      })
      .catch((error) => {
        alert(error);
    });
}

const FormatPrice = (input) => {
    // console.log(`JRTEST FormatPrice ${input} as ${typeof input}`);
    if (!input) {
        return 0.00;
    } else if (typeof input === 'string' && !isNaN(input)) {
        return Math.abs(Number(input)).toFixed(2);
    } else if (typeof input === 'number') {
        return Math.abs(input).toFixed(2);
    } else {
        return input
    }
}

const FormatDiscounts = (input) => {
    if (!input || input.length < 5) {
        return;
    }
    const items = input.split(',');
    return (
        <ul>
            {items.filter((item) => item.length > 4)
            .map((item, index) => {
                return <li key={index}>{item}</li>;
            })}
        </ul>
    );
}

const SafeAdd = (input1, input2) => {
    if (typeof input1 === 'string' && !isNaN(input1) && typeof input2 === 'string' && !isNaN(input2)) {
        return Number(input1) + Number(input2);
    } else {
        return input1 + input2;
    }
}

const ChargeSummary = ({token, order, completion, entries, numbers, discounts, discountNote, credits, fees, balance}) => {

    const payPalOptions = {
        'client-id': order.paypal_id,
        currency: 'USD',
        intent: 'capture',
    };

    const safeEntries = FormatPrice(entries);
    const safeNumbers = FormatPrice(numbers);
    const safeDiscounts = FormatPrice(discounts);
    const safeCredits = FormatPrice(credits);
    const safeFees = FormatPrice(fees);
    const safeBalance = FormatPrice(balance);
    const safeSubtotal = FormatPrice(SafeAdd(entries, numbers));
    const discountNotes = FormatDiscounts(discountNote);

    return (
        <>
            <Styles>
                <h4>Shopping Cart</h4>
                <hr></hr>
                <div id='cart-summary'>
                    <div>Entries: ${safeEntries}</div>
                    {numbers !== 0 &&
                    <div>Numbers: ${safeNumbers}</div>
                    }
                    {(safeDiscounts !== 0 || safeCredits !== 0) &&
                    <>
                    <hr></hr>
                    <div>Subtotal: ${safeSubtotal}</div>
                    <hr></hr>
                    </>
                    }
                    {discounts < 0 &&
                    <div>Discounts: ${safeDiscounts}
                        {discountNote.length > 5 &&
                            <div><strong>Thanks to: {discountNotes}</strong></div>
                        }
                    </div>
                    }
                    {credits !== 0 &&
                    <div>Credits: ${safeCredits}</div>
                    }
                    <hr></hr>
                    <div>Service Fee: ${safeFees}</div>
                    <hr></hr>
                    <div><strong>Balance: ${safeBalance}</strong></div>
                </div>
            </Styles>
            {order.balance >= 0.01 &&
                <PayPalScriptProvider options={payPalOptions}>
                    <PayPalButtonWrapper
                        token={token}
                        order={order}
                        showSpinner={true}
                        completion={completion}
                    />
                </PayPalScriptProvider>
            }
            {order.balance < 0.01 &&
                <Button
                    type="button"
                    onClick={() => SubmitZeroBalanceOrder(token, order, completion)}
                    variant="primary"
                    size="lg">
                    Submit No Payment Necessary Order
                </Button>
            }
        </>
    )
};

export default ChargeSummary;