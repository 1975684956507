import React from 'react';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import CloseButton from 'react-bootstrap/CloseButton';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

export const Cart = ({event, riders, itemCount, total, checkout, removeRider, addRiderField, removeRiderField, assignSeasonNumber, assignTempNumber}) => {

    const delayToolTip = { show: 5, hide: 200 }

    const FieldsSelected = (riderKey, fieldId) => {
        addRiderField(riderKey, fieldId);
    }

    const RemoveRiderField = (riderKey, fieldId) => {
        removeRiderField(riderKey, fieldId);
    }

    const RemoveRider = (riderKey) => {
        removeRider(riderKey);
    }

    const RiderRegisterable = (rider) => {
        return rider.default_number || rider.assignedNumber || rider.tempNumber || !event.race_event; // TODO: use event type instead of license required
    }

    const RiderFieldSelected = (rider, fieldId) => {
        return rider.selectedFields.indexOf(fieldId) >= 0;
    }

    const RiderFieldRegistered = (rider, fieldId) => {
        return rider.registeredFields.indexOf(fieldId) >= 0;
    }

    const RiderFieldAvailable = (rider, fieldId) => {
        return !RiderFieldRegistered(rider, fieldId) && !RiderFieldSelected(rider, fieldId);
    }

    const RiderFieldRow = (rider, field) => {
        return <>
            {RiderRegisterable(rider) && RiderFieldSelected(rider, field.id) &&
                SelectedRiderField(rider, field)
            }
            {RiderRegisterable(rider) && RiderFieldRegistered(rider, field.id) &&
                RegisteredRiderField(rider, field)
            }
            {RiderRegisterable(rider) && RiderFieldAvailable(rider, field.id) &&
                AvailableRiderField(rider, field)
            }
            {!RiderRegisterable(rider) &&
                LockedRiderField(rider, field)
            }
        </>
    }

    const AvailableRiderField = (rider, field) => {
        return <Button
                variant='light'
                size='lg'
                onClick={() => FieldsSelected(rider.key, field.id)}
            >
                {field.label}
            </Button>
    }

    const SelectedRiderField = (rider, field) => {
        return <Button
                variant='success'
                size='lg'
                onClick={() => RemoveRiderField(rider.key, field.id)}
            >
                {field.label}
            </Button>
    }

    const RegisteredRiderField = (rider, field) => {
        return <OverlayTrigger
            delay={delayToolTip}
            overlay={
            <Tooltip id="tooltip-disabled">
                {rider.first_name} {rider.last_name} is already registered for {field.label}.
            </Tooltip>}>
            <span className="d-inline-block w-100">
                <Button
                    variant='outline-success'
                    style={{ pointerEvents: 'none' }}
                    size='lg'
                    disabled
                >
                     {field.label}
                 </Button>
             </span>
         </OverlayTrigger>
    }

    const LockedRiderField = (rider, field) => {
        return <OverlayTrigger 
            delay={delayToolTip}
            overlay={
            <Tooltip id="tooltip-disabled">
                {rider.first_name} {rider.last_name} needs a racing number before registering for any fields.
            </Tooltip>}>
            <span className="d-inline-block w-100">
                <Button
                    variant='outline-warning'
                    style={{ pointerEvents: 'none' }}
                    size='lg'
                    disabled
                >
                    {field.label}
                </Button>
            </span>
        </OverlayTrigger>
    }

    const RiderNumberButton = (rider) => {
        if (rider.assignedNumber) {
            return <>
                <OverlayTrigger
                key={rider.key + '-assigned-number'}
                delay={delayToolTip}
                overlay={
                    <Tooltip>
                        Season number {rider.assignedNumber.number} has been selected for {rider.first_name} {rider.last_name}.
                    </Tooltip>
                }
                >
                    <Button
                        variant='success'
                        size='lg'
                        onClick={() => {assignSeasonNumber(rider)}}
                    >
                        #{rider.assignedNumber.number}
                    </Button>
                </OverlayTrigger>
            </>
        } else if (rider.tempNumber) {
            return <OverlayTrigger
                key={rider.key + '-temp-number'}
                delay={delayToolTip}
                overlay={
                    <Tooltip>
                        Temp number {rider.tempNumber} has been selected for {rider.first_name} {rider.last_name}.
                    </Tooltip>
                }
            >
                <Button
                    variant='success'
                    size='lg'
                    onClick={() => {assignSeasonNumber(rider)}}
                >
                    #{rider.tempNumber}
                </Button>
            </OverlayTrigger>
        } else if (rider.default_number && rider.assignedNumber == null) {
            return <OverlayTrigger
                delay={delayToolTip}
                overlay={
                    <Tooltip>
                        {rider.first_name} {rider.last_name} has season number {rider.default_number.number}.
                    </Tooltip>
                }
            >
                <span className="d-inline-block">
                    <Button
                        variant='outline-success'
                        size='lg'
                        style={{ pointerEvents: 'none' }}
                        disabled
                    >
                        #{rider.default_number.number}
                    </Button>
                </span>
            </OverlayTrigger>
        } else {
            return <OverlayTrigger
                key={rider.key + '-temp-number'}
                delay={delayToolTip}
                overlay={
                    <Tooltip>
                        Select a season number for {rider.first_name} {rider.last_name}.
                    </Tooltip>
                }
            >
                <Button
                    variant='warning'
                    size='lg'
                    className='mx-1'
                    onClick={() => assignSeasonNumber(rider)}
                >
                    <>Season Number</>
                </Button>
            </OverlayTrigger>
        }
    }

    const RiderTempNumberButton = (rider) => {
        if (rider.tempNumber == null) {
            return <OverlayTrigger
                key={rider.key + '-number'}
                delay={delayToolTip}
                overlay={
                    <Tooltip>
                        Select a termporary paper number for {rider.first_name} {rider.last_name}. Temporary numbers will be assigned at race checkin at the track and are only good for one event.
                    </Tooltip>
                }
            >
                <Button
                    variant='warning'
                    size='lg'
                    className='ml-2'
                    onClick={() => assignTempNumber(rider.key)}
                >
                    <>Temp</>
                </Button>
            </OverlayTrigger>
        }
    }

    return (
        <div className='cart'>
        {(!riders || riders.length == 0) &&
            <div className='my-3'>Please add one or more riders to your order.</div>
        }
        {riders && riders.length > 0 &&
        <>
            <table className='table-row d-grid gap-2'>
                <tbody className='table-row d-grid gap-2'>
                    {riders.map((rider, riderIndex) => (
                        <React.Fragment key={`riderwrapper${riderIndex}`}>
                        <tr key={`riderrow-${riderIndex}`} className='table-row rider-row'>
                            <td key={`riderrow-${riderIndex}-info`}>
                                <h3>
                                    {event.race_event &&
                                        <>
                                            {RiderNumberButton(rider)}
                                            {RiderTempNumberButton(rider)}
                                        </>
                                    }
                                    <span className='rider-name mx-3 align-middle'>{rider.first_name} {rider.last_name}</span>
                                </h3>
                            </td>
                            <td key={`riderrow-${riderIndex}-remove`}>
                                <OverlayTrigger
                                    key={rider.key}
                                    overlay={
                                        <Tooltip>
                                            Remove {rider.first_name} {rider.last_name} from this order.
                                        </Tooltip>
                                    }
                                >
                                    <CloseButton onClick={() => RemoveRider(rider.key)} />
                                </OverlayTrigger>
                            </td>
                        </tr>
                        {rider.availableFields.map((field, fieldIndex) => (
                            <tr key={`rider-field-row-${riderIndex}-${fieldIndex}`} className='table-row field-row'>
                                <td colSpan='2' key={`rider-field-row-${riderIndex}-${fieldIndex}-action`}>
                                    {RiderFieldRow(rider, field)}
                                </td>
                            </tr>
                        ))}
                        </React.Fragment>
                    ))}
                </tbody>
            </table>
            {itemCount == 0 &&
                <Button className="my-3" variant="secondary" size="lg" disabled>
                    Checkout
                    <Badge className="badge bg-secondary ml-2">{itemCount}</Badge>
                </Button>
            }
            {itemCount > 0 &&
                <Button className="my-3" variant="primary" size="lg" onClick={checkout}>
                    Checkout ${total}
                    <Badge className="badge bg-secondary ml-2">{itemCount}</Badge>
                </Button>
            }
        </>
        }
    </div>
    );
}

export default Cart;