import API from './config';

const timeout = 100000;

/**
 * Makes a GET request using authorization header. For more, visit:
 * https://tools.ietf.org/html/rfc6750
 * @param {string} accessToken 
 * @param {string} apiEndpoint 
 */
 export const callApiWithToken = async(accessToken, apiMethod) => {
    // console.log(`callApiWithToken ${accessToken}`);
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    const apiEndpoint = `${API}/${apiMethod}`;
    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);

    headers.append('X-Custom-Authorization', bearer);

    const options = {
        method: 'GET',
        headers: headers,
        signal: controller.signal
    };

    const response = fetch(apiEndpoint, options)
        .then(handleErrors)
        .then((response) => {
            return {data: response, status: response.status};
        })
        .catch((error) => {
            throw error;
        });

    clearTimeout(id);

    return response;
}

/**
 * Makes a GET request using authorization header. For more, visit:
 * https://tools.ietf.org/html/rfc6750
 * @param {string} accessToken 
 * @param {string} apiEndpoint 
 */
 export const callApiWithBodyAndToken = async(accessToken, apiMethod, body) => {
    // console.log(`callApiWithBodyAndToken ${accessToken}`);
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    const apiEndpoint = `${API}/${apiMethod}`;
    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);

    headers.append('X-Custom-Authorization', bearer);

    const options = {
        method: 'POST',
        body: body,
        headers: headers,
        signal: controller.signal
    };

    const response = fetch(apiEndpoint, options)
        .then(handleErrors)
        .then((response) => {
            return {data: response, status: response.status};
        })
        .catch((error) => {
            throw error;
        });

    clearTimeout(id);

    return response;
}

function handleErrors(response) {
    if (!response.ok) {
        throw Error(`${response.statusText} Exception`);
    }
    return response.json();
}
