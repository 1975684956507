import React from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

export const Confirmation = ({event, restart}) => {

    const openRaceResults = () => {
        window.open(`${process.env.REACT_APP_VELOREG_URL}/race-results/${event.id}`);
    }

    const openStartList = () => {
        window.open(`${process.env.REACT_APP_VELOREG_URL}/field-start-list/${event.id}`);
    }

    return (
        <Card>
            <Card.Header>Confirmation</Card.Header>
            <Card.Body>
                <Card.Title>Thank you for registering!</Card.Title>
                <Card.Text className='mt-4'>
                    See who else is registered
                </Card.Text>
                <Button variant="primary" onClick={openStartList}>Start List</Button>
                <Card.Text className='mt-4'>
                    See live results once racing starts
                </Card.Text>
                <Button variant="primary" onClick={openRaceResults}>Results</Button>
                <Card.Text className='mt-4'>
                    Register again
                </Card.Text>
                <Button variant="primary" onClick={restart}>Start Over</Button>
            </Card.Body>
        </Card>
    );
}

export default Confirmation;