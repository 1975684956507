import React from 'react';
import { Button, Form } from 'react-bootstrap';
import { useState, useRef } from 'react';
import { DateSelect } from 'react-ymd-date-select/dist/cjs/presets/vanilla'
import { RiderBirthYear } from '../../riderLib'
import { callApiWithBodyAndToken } from '../../fetch';

import { PDFDocument, StandardFonts, rgb } from 'pdf-lib';
import SignatureCanvas from 'react-signature-canvas';

const usacTemplateUrl = process.env.REACT_APP_WAIVER_USAC_URL; // 'http://localhost:3000/2023-USAC-Waiver.pdf';
const atraTemplateUrl = process.env.REACT_APP_WAIVER_ATRA_URL; // 'http://localhost:3000/2023-ATRA-ACCIDENT-WAIVER.pdf';

export const Waiver = ({token, rider, idpUser, waiverType, skipWaiver, acceptWaiver}) => {

    const iframeRef = useRef();
    var signatureCanvas = useRef();

    const [type] = useState(waiverType)
    const [step, setStep] = useState(rider.age >= 18 ? 'riderAttestation' : 'guardianAttestation');
    const [user] = useState(idpUser);
    const defaultName = rider.first_name + ' ' + rider.last_name;
    const [riderName] = useState(rider.first_name && rider.last_name ? defaultName : '');

    const [riderAge, setRiderAge] = useState(rider.age ? rider.age : '');
    const [riderCat] = useState(rider.track_cat ? rider.track_cat : 5);
    const [riderDOBString, setRiderDOBString] = useState('');
    const [riderEmail, setRiderEmail] = useState(user.idTokenClaims.emails[0]);
    const [riderTeam, setRiderTeam] = useState(rider.track_team ? rider.track_team : '');
    const [riderAddress, setRiderAddress] = useState(rider.address ? rider.address : '');
    const [riderCity, setRiderCity] = useState(rider.city ? rider.city : '');
    const [riderState, setRiderState] = useState(rider.state ? rider.state : '');
    const [riderZip, setRiderZip] = useState(rider.zip ? rider.zip : '');
    const [riderPhone, setRiderPhone] = useState(rider.phone ? rider.phone : '');
    const [riderWorkPhone, setRiderWorkPhone] = useState('');
    const [riderSignature, setRiderSignature] = useState('');
    const [contactName, setContactName] = useState('');
    const [contactNumber, setContactNumber] = useState('');
    const [guardianName, setGuardianName] = useState('');
    const [guardianSignature, setGuardianSignature] = useState('');
    const [waiverData, setWaiverData] = useState('<h1>Loading...</h1>');
    const height = '500px';
    //const defaultDate = new Date(RiderBirthYear(rider), 0, 1);
    const riderBirthYear = RiderBirthYear(rider);
    
    const FillPdf = async() => {
        if (type == 'usac') {
            FillUSACWaiverPdf();
        } else if (type == 'atra') {
            FillATRAWaiverPdf()
        }
    }

    const FillATRAWaiverPdf = async() => {

        const existingPdfBytes = await fetch(atraTemplateUrl).then(res => res.arrayBuffer())
        const pdfDoc = await PDFDocument.load(existingPdfBytes)

        const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)
        const pages = pdfDoc.getPages()
        const fontSize = 12
        const subFontSize = 10
        //const { docHeight } = pages[0].getSize()
        var now = new Date();

        DrawString(pages[0], `${now.getMonth() + 1}/${now.getDate()}/${now.getFullYear()}`, fontSize, helveticaFont, 89, 329);
        DrawString(pages[0], riderAge, fontSize, helveticaFont, 203, 329);
        DrawString(pages[0], riderCat, fontSize, helveticaFont, 388, 329);

        DrawString(pages[0], riderTeam, fontSize, helveticaFont, 82, 306);
        DrawString(pages[0], riderName, fontSize, helveticaFont, 90, 283);

        DrawString(pages[0], riderAddress, fontSize, helveticaFont, 61, 261);
        DrawString(pages[0], riderCity, fontSize, helveticaFont, 51, 235);
        DrawString(pages[0], riderState, fontSize, helveticaFont, 426, 235);
        DrawString(pages[0], riderZip, fontSize, helveticaFont, 496, 235);

        DrawString(pages[0], riderPhone, fontSize, helveticaFont, 97, 211);
        DrawString(pages[0], riderWorkPhone, fontSize, helveticaFont, 267, 211);

        DrawString(pages[0], contactName, fontSize, helveticaFont, 193, 187);
        DrawString(pages[0], contactNumber, fontSize, helveticaFont, 407, 187);

        if (NeedsJuniorWaiver()) {
            DrawString(pages[0], guardianName, fontSize, helveticaFont, 180, 40);
//            DrawString(pages[0], `${now.getMonth() + 1}/${now.getDate()}/${now.getFullYear()}`, fontSize, helveticaFont, 451, 52);
        }
        const userString = `${user.idTokenClaims.given_name} ${user.idTokenClaims.family_name} <${user.idTokenClaims.emails[0]}>`
        DrawString(pages[0], `Electronically signed by: ${userString} ${now.getMonth() + 1}/${now.getDate()}/${now.getFullYear()}`, subFontSize, helveticaFont, 80, 10);
        // DrawString(pages[0], `Electronically signed by: ${userString}`, subFontSize, helveticaFont, 80, 10);

        if (riderSignature && riderSignature != '') {
            await DrawSignature(pdfDoc, pages[0], riderSignature, 130, 155);
        }

        if (guardianSignature && guardianSignature != '') {
            await DrawSignature(pdfDoc, pages[0], guardianSignature, 180, 55);
        }

        const data = await pdfDoc.saveAsBase64({ dataUri: true });
        setWaiverData(data);
    }

    const FillUSACWaiverPdf = async() => {
    
        const existingPdfBytes = await fetch(usacTemplateUrl).then(res => res.arrayBuffer())
        const pdfDoc = await PDFDocument.load(existingPdfBytes)
    
        const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)
        const pages = pdfDoc.getPages()
        const fontSize = 12
        const subFontSize = 10
        //const { docHeight } = pages[0].getSize()
        var now = new Date();
        var riderDOB = new Date(Date.parse(riderDOBString + 'T22:00:00+00:00')); // Lazy hack to avoid time zone issues

        DrawString(pages[0], riderName, fontSize, helveticaFont, 22, 136);
        DrawString(pages[0], riderEmail, fontSize, helveticaFont, 453, 110);
        DrawString(pages[0], contactName, fontSize, helveticaFont, 22, 108);
        DrawString(pages[0], contactNumber, fontSize, helveticaFont, 229, 108);
        DrawString(pages[0], `${riderDOB.getMonth() + 1}/${riderDOB.getDate()}/${riderDOB.getFullYear()}`, fontSize, helveticaFont, 514, 136);
        DrawString(pages[0], `${now.getMonth() + 1}/${now.getDate()}/${now.getFullYear()}`, fontSize, helveticaFont, 451, 136);

        if (NeedsJuniorWaiver()) {
            DrawString(pages[0], guardianName, fontSize, helveticaFont, 22, 52);
            DrawString(pages[0], `${now.getMonth() + 1}/${now.getDate()}/${now.getFullYear()}`, fontSize, helveticaFont, 451, 52);
        }
        const userString = `${user.idTokenClaims.given_name} ${user.idTokenClaims.family_name} <${user.idTokenClaims.emails[0]}>`
        DrawString(pages[0], `Electronically signed by: ${userString} ${now.getMonth() + 1}/${now.getDate()}/${now.getFullYear()}`, subFontSize, helveticaFont, 80, 10);

        if (riderSignature && riderSignature != '') {
            await DrawSignature(pdfDoc, pages[0], riderSignature, 229, 129);
        }

        if (guardianSignature && guardianSignature != '') {
            await DrawSignature(pdfDoc, pages[0], guardianSignature, 229, 40);
        }

        const data = await pdfDoc.saveAsBase64({ dataUri: true });
        setWaiverData(data);
    }

    const DrawString = (page, text, fontSize, font, x, y) => {
        page.drawText(text, {
            x: x,
            y: y,
            size: fontSize,
            font: font,
            color: rgb(0, 0, 0),
        })
    }

    const DrawSignature = async (pdfDoc, page, url, x, y) => {
        // console.log(url);
        const pngImageBytes = await fetch(url).then((res) => res.arrayBuffer());
        const pngImage = await pdfDoc.embedPng(pngImageBytes);
        const pngDims = pngImage.scale(0.07);

        page.drawImage(pngImage, {
            x: x,
            y: y,
            width: pngDims.width,
            height: pngDims.height
        });
    }

    const ClearSignature = () => {
        signatureCanvas.current.clear();
    }

    /*
    const SignatureIsClear = () => {
        return !signatureCanvas || !signatureCanvas.current || signatureCanvas.current.isEmpty();
    }
    */

    const AcceptRiderSignature = () => {
        if (!signatureCanvas.current.isEmpty()) {
            setRiderSignature(signatureCanvas.current.getTrimmedCanvas().toDataURL('image/png'));
            PreviewWaiver();
        }
    }

    const AcceptGuardianSignature = () => {
        setGuardianSignature(signatureCanvas.current.getTrimmedCanvas().toDataURL('image/png'));
        PreviewWaiver();
    }

    const NotRider = () => {
        if (rider.age <= 18) {
            GuardianCheck();
        } else {
            ShowInPerson();
        }
    }

    const RiderAttestation = () => {
        setStep('riderAttestation');
    }

    const GuardianCheck = () => {
        setStep('guardianAttestation');
    }

    const ShowInPerson = () => {
        setStep('inPersonWaiver');
    }

    const EditWaiver = () => {
        setStep('waiverForm');
    }
    
    const PreviewWaiver = () => {
        setStep('waiverPreview');
    }

    const SignRiderWaiver = () => {
        setStep('riderSignature');
    }

    const SignGuardianWaiver = () => {
        setStep('guardianSignature');
    }

    const SubmitWaiver = () => {
        const waiverWrapper = {
            'type': type,
            'rider': rider,
            'email': riderEmail,
            'waiver': waiverData
        };

        callApiWithBodyAndToken(token, 'waivers', JSON.stringify(waiverWrapper)).then((response) => {
            console.log(response);
            acceptWaiver(response.data.rider);
        })
        .catch((error) => {
            return alert(error);
        });
    }
    
    const NeedsJuniorWaiver = () => {
        if (type == 'usac') {
            var now = new Date().getTime();
            var riderDOB = new Date(riderDOBString + 'T22:00:00+00:00');
            var dob = new Date(riderDOB).getTime();
            var diff = (now - dob) / 1000;
            return diff < (31536000 * 18);
        } else {
            return riderAge < 18;
        }
    }
    
    const ATRAFormComplete = () => {
        return ValidName(riderName) && ValidEmailAddress() && ValidAddress() && ValidCity() && ValidState() && ValidZip() && ValidPhone(riderPhone) && ValidName(contactName) && ValidPhone(contactNumber) && (!NeedsJuniorWaiver() || ValidName(guardianName));
    }

    const USACFormComplete = () => {
        return riderDOBString && ValidName(riderName) && ValidEmailAddress() && contactName && ValidPhone(contactNumber) && (!NeedsJuniorWaiver() || ValidName(guardianName));
    }

    const WaiverFormAdultSigned = () => {
        return !NeedsJuniorWaiver() && riderSignature;
    }

    const WaiverFormGuardianSigned = () => {
        return NeedsJuniorWaiver() && guardianSignature;
    }

    const ValidEmailAddress = () => {
        return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(riderEmail);
    }

    const ValidAddress = () => {
        return riderAddress && riderAddress.length > 3;
    }

    const ValidCity = () => {
        return riderCity && riderCity.length > 2;
    }

    const ValidState = () => {
        return riderState && riderState.length == 2;
    }

    const ValidZip = () => {
        return !isNaN(riderZip) && riderZip.length == 5;
    }

    const ValidAge = () => {
        return !isNaN(riderAge) && riderAge.length > 0;
    }

    const ValidPhone = (phone) => {
        return /^(\+?\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/i.test(phone);
    }

    const ValidName = (name) => {
        return name && name.length >= 1;
    }

    /*
    const onLoad = () => {
        setHeight(ref.current.contentWindow.document.body.scrollHeight + 'px');
    };
    */

    const UserAttestationForm = () => {
        return <>
            <Form>
                <h3>Are you {riderName}</h3>
                <Button variant="primary" onClick={EditWaiver}>Yes</Button>
                <Button variant="primary" className='ml-2' onClick={NotRider}>No</Button>
            </Form>
        </>
    }

    const GuardianAttestationForm = () => {
        return <>
            <Form>
                <Button variant="primary" className='mb-2' onClick={RiderAttestation}>Back</Button>
                <h3>Are you the legal guardian of {riderName}</h3>
                <Button variant="primary" className='mb-2' onClick={EditWaiver}>Yes</Button>
                <Button variant="primary" className='ml-2 mb-2' onClick={ShowInPerson}>No</Button>
            </Form>
        </>
    }

    const InPersonWaiver = () => {
        return <>
            <Form>
                <Button variant="primary" className='mb-2' onClick={RiderAttestation}>Back</Button>
                {rider.age >= 18 &&
                    <h3>
                    {riderName} will need to sign a waiver at race check-in before being allowed to race.
                    </h3>
                }
                {rider.age < 18 &&
                    <h3>
                    The legal guardian of {riderName} will need to sign a waiver at race check-in before {riderName} will be allowed to race.
                    </h3>
                }
                <Button variant="primary" className='mb-2' onClick={skipWaiver}>Skip Waiver</Button>
            </Form>
        </>
    }

    const WaiverForm = () => {
        return <>
            <Form>
                <Button variant="primary" className="mb-2" onClick={RiderAttestation}>Back</Button>
                {(type == 'atra') &&
                    <h3>ATRA Waiver</h3>
                }
                {(type == 'usac') &&
                    <h3>USA Cycling Waiver</h3>
                }
                {(type == 'usac') &&
                    <Form.Group className="mb-3" controlId="formRiderDOB">
                        <Form.Label className='font-weight-bold'>* Rider Date of Birth</Form.Label>
                        <div className='dateSelection'>
                            <DateSelect
                                value={riderDOBString + 'T22:00:00+00:00'}
                                onChange={setRiderDOBString}
                                monthFormat='MMM'
                                firstYear={riderBirthYear}
                                lastYear={riderBirthYear}
                                />
                        </div>
                    </Form.Group>
                }

                <Form.Group className="mb-3" controlId="email">
                    <Form.Label className='font-weight-bold'>* Participant Email Address</Form.Label>
                    <Form.Control
                        type="email"
                        className={ValidEmailAddress() ? 'is-valid' : ''}
                        onChange={(e) => setRiderEmail(e.target.value)}
                        value={riderEmail}
                        required />
                </Form.Group>

                {(type == 'atra') &&
                    <Form.Group className="mb-3" controlId="address">
                        <Form.Label className='font-weight-bold'>* Racing Age</Form.Label>
                        <Form.Control
                            type="text"
                            className={ValidAge() ? 'is-valid' : ''}
                            onChange={(e) => setRiderAge(e.target.value)}
                            value={riderAge}
                            required />
                    </Form.Group>
                }

                {(type == 'atra') &&
                    <Form.Group className="mb-3" controlId="address">
                        <Form.Label className='font-weight-bold'>Club / Team</Form.Label>
                        <Form.Control type="text" onChange={(e) => setRiderTeam(e.target.value)} value={riderTeam} />
                    </Form.Group>
                }

                {(type == 'atra') &&
                    <Form.Group className="mb-3" controlId="address">
                        <Form.Label className='font-weight-bold'>* Street Address</Form.Label>
                        <Form.Control
                            type="text"
                            className={ValidAddress() ? 'is-valid' : ''}
                            onChange={(e) => setRiderAddress(e.target.value)}
                            value={riderAddress}
                            required />
                    </Form.Group>
                }

                {(type == 'atra') &&
                    <Form.Group className="mb-3" controlId="state">
                        <Form.Label className='font-weight-bold'>* City</Form.Label>
                        <Form.Control
                            type="text"
                            className={ValidCity() ? 'is-valid' : ''}
                            onChange={(e) => setRiderCity(e.target.value)}
                            value={riderCity}
                            required />
                    </Form.Group>
                }

                {(type == 'atra') &&
                    <Form.Group className="mb-3" controlId="state">
                        <Form.Label className='font-weight-bold'>* State</Form.Label>
                        <Form.Control
                            type="text"
                            className={ValidState() ? 'is-valid' : ''}
                            onChange={(e) => setRiderState(e.target.value)}
                            value={riderState}
                            required />
                    </Form.Group>
                }

                {(type == 'atra') &&
                    <Form.Group className="mb-3" controlId="zip">
                        <Form.Label className='font-weight-bold'>* Zip</Form.Label>
                        <Form.Control
                            type="number"
                            className={ValidZip() ? 'is-valid' : ''}
                            onChange={
                                (e) => {
                                    const numericValue = e.target.value.replace(/[^0-9]/g, '');
                                    setRiderZip(numericValue);
                                }
                            }
                            value={riderZip} />
                    </Form.Group>
                }

                {(type == 'atra') &&
                    <Form.Group className="mb-3" controlId="phone">
                        <Form.Label className='font-weight-bold'>* Phone Number</Form.Label>
                        <Form.Control
                            type="tel"
                            className={ValidPhone(riderPhone) ? 'is-valid' : ''}
                            onChange={(e) => setRiderPhone(e.target.value)}
                            value={riderPhone}
                            required />
                    </Form.Group>
                }

                {(type == 'atra') &&
                    <Form.Group className="mb-3" controlId="workPhone">
                        <Form.Label className='font-weight-bold'>Work Phone</Form.Label>
                        <Form.Control
                            type="tel"
                            onChange={(e) => setRiderWorkPhone(e.target.value)}
                            value={riderWorkPhone}
                            required />
                    </Form.Group>
                }

                <Form.Group className="mb-3" controlId="formContactName">
                    <Form.Label className='font-weight-bold'>* Emergency Contact Name</Form.Label>
                    <Form.Control
                        type="text"
                        className={ValidName(contactName) ? 'is-valid' : ''}
                        onChange={(e) => setContactName(e.target.value)}
                        value={contactName}
                        required />
                </Form.Group>
    
                <Form.Group className="mb-3" controlId="formContactNumber">
                    <Form.Label className='font-weight-bold'>* Emergency Contact Number</Form.Label>
                    <Form.Control
                        type="tel"
                        className={ValidPhone(contactNumber) ? 'is-valid' : ''}
                        onChange={(e) => setContactNumber(e.target.value)}
                        value={contactNumber}
                        required />
                </Form.Group>
    
                {(NeedsJuniorWaiver()) &&
                    <Form.Group className="mb-3" controlId="formGuardian">
                        <Form.Label className='font-weight-bold'>* Name of Parent / Guardian</Form.Label>
                        <Form.Control
                            type="text"
                            className={ValidName(guardianName) ? 'is-valid' : ''}
                            onChange={(e) => setGuardianName(e.target.value)}
                            value={guardianName}
                            required />
                    </Form.Group>
                }
    
                {(type == 'atra') &&
                    ATRAWaiverFormButtons()
                }
                {(type == 'usac') &&
                    USACWaiverFormButtons()
                }
            </Form>
        </>
    }

    const USACWaiverFormButtons = () => {
        return <>
            {!USACFormComplete() &&
                <Button variant="primary" disabled className='mb-2' onClick={PreviewWaiver}>Preview Waiver</Button>
            }
            {USACFormComplete() &&
                <Button variant="primary" type="button" className='mb-2' onClick={PreviewWaiver}>Preview Waiver</Button>
            }
        </>
    }

    const ATRAWaiverFormButtons = () => {
        return <>
            {!ATRAFormComplete() &&
                <Button variant="primary" disabled className='mb-2' onClick={PreviewWaiver}>Preview Waiver</Button>
            }
            {ATRAFormComplete() &&
                <Button variant="primary" type="button" className='mb-2' onClick={PreviewWaiver}>Preview Waiver</Button>
            }
        </>
    }

    const RiderSignaturePad = () => {
        console.log(`Starting RiderSignaturePad`);
        return <>
            <Form>
                <Button variant="primary" onClick={PreviewWaiver} className='mb-2'>Back</Button>
                <h3>Sign Waiver</h3>
                <Form.Group className="mb-3" controlId="formContactNumber">
                    <SignatureCanvas 
                        minWidth={1}
                        maxWidth={5}
                        canvasProps={{ className: 'sigCanvas'}}
                        ref={signatureCanvas} />
                </Form.Group>
                <Button variant="primary" onClick={AcceptRiderSignature} className='mb-2'>Accept</Button>
                <Button variant="primary" onClick={ClearSignature} className='ml-2 mb-2'>Clear</Button>
            </Form>
        </>
    }

    const GuardianSignaturePad = () => {
        return <>
            <Form>
                <Button variant="primary" onClick={PreviewWaiver} className='mb-2'>Back</Button>
                <h3>Guardian Signature</h3>
                <Form.Group className="mb-3" controlId="formContactNumber">
                    <SignatureCanvas
                        minWidth={1}
                        maxWidth={5}
                        canvasProps={{ className: 'sigCanvas'}}
                        ref={signatureCanvas} />
                </Form.Group>
                <Button variant="primary" onClick={AcceptGuardianSignature} className='mb-2'>Accept</Button>
                <Button variant="primary" onClick={ClearSignature} className='ml-2 mb-2'>Clear</Button>
            </Form>
        </>
    }

    const CurrentStep = () => {
        switch (step) {
            case 'riderAttestation':
                return (
                    <div>
                        {UserAttestationForm()}
                    </div>
                )
            case 'guardianAttestation':
                return (
                    <div>
                        {GuardianAttestationForm()}
                    </div>
                )
            case 'inPersonWaiver':
                    return (
                        <div>
                            {InPersonWaiver()}
                        </div>
                    )
            case 'waiverForm':
                return (
                    <div>
                        {WaiverForm()}
                    </div>
                )
            case 'waiverPreview':
                FillPdf();
                return (
                    <div>
                        <Button
                            type="button"
                            onClick={() => EditWaiver()}
                            variant="primary"
                            className='mb-2'
                            size="lg">
                            Edit Values
                        </Button>
                        {(!NeedsJuniorWaiver() && !WaiverFormAdultSigned()) &&
                            <Button
                                type="button"
                                onClick={() => SignRiderWaiver()}
                                variant="primary"
                                className='ml-2 mb-2'
                                size="lg">
                                Sign Waiver
                            </Button>
                        }
                        {(!NeedsJuniorWaiver() && WaiverFormAdultSigned()) &&
                            <Button
                                type="button"
                                onClick={() => SignRiderWaiver()}
                                variant="primary"
                                className='ml-2 mb-2'
                                size="lg">
                                Edit Signature
                            </Button>
                        }
                        {(NeedsJuniorWaiver() && !WaiverFormGuardianSigned()) &&
                            <Button
                                type="button"
                                onClick={() => SignGuardianWaiver()}
                                variant="primary"
                                className='ml-2 mb-2'
                                size="lg">
                                Guardian Signature
                            </Button>
                        }
                        {(NeedsJuniorWaiver() && WaiverFormGuardianSigned()) &&
                            <Button
                                type="button"
                                onClick={() => SignGuardianWaiver()}
                                variant="primary"
                                className='ml-2 mb-2'
                                size="lg">
                                Edit Signature
                            </Button>
                        }
                        {(WaiverFormAdultSigned() || WaiverFormGuardianSigned()) &&
                            <Button
                                type="button"
                                onClick={SubmitWaiver}
                                variant="primary"
                                className='ml-2 mb-2'
                                size="lg">
                                Accept Waiver
                            </Button>
                        }
                        <iframe src={waiverData}
                            ref={iframeRef}
                            id='waiverFrame'
                            height={height}
                            width='100%'
                            title='waiverFrame'
                            style={{
                                width: '100%',
                                overflow: 'auto',
                            }}
                        ></iframe>
                    </div>
                )
            case 'riderSignature':
                return (
                    <div>
                        {RiderSignaturePad()}
                    </div>
                )
            case 'guardianSignature':
                return (
                    <div>
                        {GuardianSignaturePad()}
                    </div>
                )
            default:
                return (
                    <div>State Error</div>
                );
        }
    }

    return <div>
        {CurrentStep()}
    </div>
}

export default Waiver;
