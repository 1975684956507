export const AvailableRiderFields = (event, rider) => {
    return event.fields.filter((field) => {
        // console.log('AvailableRiderFields step 1');
        if (field.min_age !== null || field.max_age !== null) { // bypass age checks for special cases, like clinics
            if (field.min_age && Number(rider.age) < Number(field.min_age)) { return false };
            if (field.max_age && Number(rider.age) > Number(field.max_age)) { return false };
        }

        if (RiderAllowedInField(rider, field).length == 0) { return false };

        return true;
    }).sort((a, b) => {
        const aIsJunior = a.max_age !== null && Number(a.max_age) <= 18 && a.min_age !== null && Number(a.min_age) > 0;
        const bIsJunior = b.max_age !== null && Number(b.max_age) <= 18 && b.min_age !== null && Number(b.min_age) > 0;
        const aIsMaster = a.min_age !== null && Number(a.min_age) >= 30;
        const bIsMaster = b.min_age !== null && Number(b.min_age) >= 30;
        //const aIsCat = !aIsJunior && !aIsMaster;
        //const bIsCat = !bIsJunior && !bIsMaster;

        // console.log(`aIsJunior ${aIsJunior} vs bIsJunior ${bIsJunior}`);
        // console.log(`aIsMaster ${aIsMaster} vs bIsMaster ${bIsMaster}`);

        // Juniors above others
        if (aIsJunior && !bIsJunior) return -1;
        else if (!aIsJunior && bIsJunior) return 1;

        // Masters below others
        else if (aIsMaster && !bIsMaster) return 1;
        else if (!aIsMaster && bIsMaster) return -1;

        // Juniors / Masters by age
        else if ((aIsJunior && bIsJunior) || (aIsMaster && bIsMaster)) {
            return Number(a.min_age) - Number(b.min_age);
        }

        else if (a.categories[0].gender != null && b.categories[0].gender != null
            && a.categories[0].gender != b.categories[0].gender) {
            return (a.categories[0].gender < b.categories[0].gender) ? -1 : 1;
        }

        else if (a.categories[0].track_cat != null && b.categories[0].track_cat != null) {
            return (a.categories[0].track_cat < b.categories[0].track_cat) ? -1 : 1;
        }

        else {
            return 0;
        }
    });
}

const RiderAllowedInField = (rider, field) => {
    // console.log(`RiderAllowedInField Field ==> ${JSON.stringify(field)}`);
    return field.categories.filter((category) => {
        // console.log(`RiderAllowedInField Category ==> ${JSON.stringify(category)}`);
        // console.log(`RiderAllowedInField Gender ${category.gender} vs ${rider.gender} = ${(rider.gender === 'N/A' && category.gender === 'M')}`);
        if (category.gender !== rider.gender && !(rider.gender === 'N/A' && category.gender === 'M')) { return false };
        // console.log(`RiderAllowedInField Cat ${category.track_cat} vs ${rider.track_cat}`);
        if (Number(category.track_cat) != Number(rider.track_cat) && category.track_cat != rider.track_cat) { 
            return false
        };
        // console.log('RiderAllowedInField step 2');

        return true;
    });
}